import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_year_photo_calendar from '../../../../../../../common/src/assets/image/example_year_photo_calendar.png';
import template_options_year_photo_calendar from '../../../../../../../common/src/assets/image/template_options_year_photo_calendar.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        A year photo template, like a traditional wall calendar. Each month will
        be printed to a single page in portrait orientation, with a photo on the
        top half of the page.
      </p>
      <p>
        <Image alt="" src={example_year_photo_calendar} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The Year template provides the following options when creating a calendar...
      </p>
      <Image
        alt=""
        src={template_options_year_photo_calendar}
        style={{ width: 448 }}
      />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Year</strong>
            </td>
            <td>The year to print. The first month will be January.</td>
          </tr>
          <tr>
            <td>
              <strong>First day of week</strong>
            </td>
            <td>The first day of the week will be the left-most day column.</td>
          </tr>
          <tr>
            <td>
              <strong>Text behavior</strong>
            </td>
            <td>
              Titles can be wrapped (if space allows), never wrapped, or shrunk
              to fit so that wrapping is unnecessary. You can pick the desired
              behavior via the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item font size</strong>
            </td>
            <td>
              Override the default font size associated with each calendar data
              source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item sort order</strong>
            </td>
            <td>
              Sort items by either start time or calendar data source. When
              sorted by calendar data source, items will be effectively grouped
              based on their associated calendar data source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time Format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Only use background color for all-day items</strong>
            </td>
            <td>
              When checked, only events or tasks that are all-day or span
              multiple days will have a background color. Other items will have
              their text colored without a background.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show start time</strong>
            </td>
            <td>
              When checked, event or task start times will be shown. The
              ordering of times, title, and location values can be specified in
              the <Link to="/Documentation/Options">PrintableCal options</Link>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show end time</strong>
            </td>
            <td>
              <span>When checked, event or task end times will be shown. </span>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use bold text for item times</strong>
            </td>
            <td>
              When checked, item titles will be in bold text, to help
              differentiate from the location and description values.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show location</strong>
            </td>
            <td>When checked, event or task locations will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Show descriptions within items</strong>
            </td>
            <td>
              When checked, descriptions will be shown within items if space is
              available.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show descriptions in tooltips</strong>
            </td>
            <td>
              When checked, an item's description will be added to a comment
              that pops up when hovering the mouse over the item. Note, this
              only works when calendars are generated in Excel, not Word. Also,
              these comments won't be included in the printout.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Clip items in alternate months</strong>
            </td>
            <td>
              When checked, events or tasks that occur in the previous or
              next month will not be shown.
            </td>
          </tr>
          <tr>
            <td>
              <strong>January, February, March, etc...</strong>
            </td>
            <td>
              A picture can be selected below each month label. To select a
              picture, click the white box below the month label that says{' '}
              <strong>Click to select an image</strong>. After selecting an
              image, a thumbnail preview will be shown below the month label.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Year-Photo-Calendar"
      commentsId="commentsplus_post_1195_489"
      title="Year Photo Calendar Template"
      description="A year photo template. Each month will be printed to a single page in portrait orientation, with a photo on the top half of the page."
      keywords="year photo calendar, year template, wall calendar, 2020 template, calendar template, print 2020, print calendar, print holidays, 2020 holidays, Excel calendar, Word calendar"
      content={content}
    />
  );
};

export default Documentation;
